:root {
  --darkPink: #e09e9e;
  --pink: rgb(248, 211, 197);
  --lightPink: rgb(252, 238, 233);
  --lightGreen: rgb(221, 230, 213);
  --green: rgb(163, 184, 153);
  --darkGreen: #667b68;
  --greenText: #394439;
  --lightPurple: rgb(248, 244, 255);
  --purple: rgb(232, 221, 247);
  --darkPurple: rgb(190, 167, 221);
  --purpleText: #50465e;
  --white: rgb(253, 253, 250);
}

@font-face {
  font-family: "Nunito";
  src: url("/src/assets/fonts/Nunito.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}

.App {
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.App h1 {
  box-sizing: border-box;
  font-size: 2.25rem;
  padding-top: 1rem;
  hyphens: manual;
  font-family: "Nunito", sans-serif;
  color: var(--purpleText);
  text-shadow: 1px 0 0 var(--darkPink), 0 -1px 0 var(--pink),
    0 1px 0 var(--darkPink), -1px 0 0 var(--pink),
    4px 4px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 2px;
}

.sectionHeading {
  box-sizing: border-box;
  color: var(--purpleText);
  width: 100%;
  max-width: 65ch;
  padding: 1rem;
  border: 5px solid;
  background-color: var(--white);
  box-shadow: 10px 10px 2px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  margin: 1rem;
}

.sectionHeading.calculatorHeading {
  border-color: var(--darkGreen);
  color: var(--greenText);
  padding-bottom: 2rem;
}

.sectionHeading.tableHeading {
  border-color: var(--darkPink);
  padding-bottom: 2rem;
}

.App h2 {
  font-family: "Nunito", "Montserrat", sans-serif;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.15px;
}

.App p {
}

.italic {
  font-style: italic;
}

.sectionDivider {
  border-bottom: 9px dotted var(--purpleText);
  width: 8ch;
  height: 0;
  margin: 2.5rem 0 2rem;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

@media (min-width: 375px) {
  .sectionHeading {
    padding: 1rem 4rem;
  }
}
