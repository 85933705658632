.descriptorLabel {
  display: block;
  margin: 1rem 0;
}

.descriptorSelect {
  padding: 0.5rem;
  text-align: center;
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1);
}

.calculator .descriptorSelect {
  border: 4px solid var(--darkGreen);
  background-color: var(--white);
  margin-bottom: 0.5rem;
}

.calculator .descriptorLabel {
  margin: 1.5rem 0 0.5rem 0;
  min-height: 1.5rem;
  font-weight: bold;
}

.tableHeading .descriptorSelect {
  border: 4px solid var(--darkPink);
  background-color: var(--lightPink);
}
