.calculator {
  color: var(--greenText);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--darkGreen);
  background-color: var(--lightGreen);
  padding: 2rem 1rem;
  margin: 1rem;
  box-shadow: 10px 10px 2px rgba(0, 0, 0, 0.1);
  max-width: 65ch;
  width: 100%;
}

.sdControls {
  width: 100%;
}

.sdControls label {
  font-weight: bold;
  display: block;
  padding: 1rem 0 0.5rem 0;
}

.smallDividerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem;
}
.smallDivider {
  border-bottom: 6px dotted var(--darkGreen);
  width: 50px;
}

.scaleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  padding-bottom: 0;
}

.nonConvertibleContainer {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  /* border: 1px solid green; */
}

.descriptionContainer,
.percentileContainer {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scaleLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.5rem;
  font-weight: bold;
}

.score {
  box-sizing: border-box;
  width: 10ch;
  text-align: center;
  min-height: 1.5rem;
  padding: 0.5rem 0;
  padding-left: 0.5rem;
  border: 3px solid var(--darkGreen);
  background-color: var(--white);
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;
  color: var(--greenText);
}

.scoreLike {
  box-sizing: border-box;
  width: 10ch;
  min-height: 1.5rem;
  padding: 0.25rem 0.5rem;
}

.score.active {
  border: 3px solid var(--darkPink);
}

.score.active:focus {
  outline: none;
  border: 3px solid var(--darkPink);
}

.description,
.percentile {
  min-width: 15ch;
  margin: 0.5rem 0;
}

.calculator button {
  display: none;
  color: var(--greenText);
  background-color: var(--white);
  padding: 0.75rem 1.25rem;
  border: 3px solid var(--darkGreen);
  cursor: pointer;
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.calculator button:hover {
  /* background-color: var(--lightPink); */
  border-color: var(--darkPink);
  text-decoration: underline;
}

.calculator button:active {
  filter: brightness(90%);
  transform: scale(99%);
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1);
}
