.tableContainer {
  max-width: 65ch;
  width: 100%;
  max-height: 95vh;
  color: var(--purpleText);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid var(--darkPink);
  background-color: var(--white);
  margin: 1rem;
  box-shadow: 10px 10px 2px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: auto;
}

table {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
}

thead {
  background-color: var(--pink);
  position: sticky;
  top: 0;
}

thead tr:hover {
  background-color: var(--pink);
}

th {
  padding: 0.5rem 0.25rem;
}

th.clickable {
  cursor: pointer;
}

th.clickable:hover {
  background-color: var(--lightPink);
}

.thContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thText {
  display: block;
  text-decoration: underline;
}

.thSymbol {
  display: block;
}

.thSymbol {
  text-decoration: none;
}

.activeCol {
  background-color: var(--darkPink);
}

th.activeCol:hover {
  background-color: var(--darkPink);
}

tr:nth-child(even) {
  background-color: var(--lightPink);
}

tr:hover {
  background-color: var(--darkPink);
}

td {
  padding: 0.25rem 0.75rem;
  height: 42px;
  width: 10ch;
}

.tdDescription {
  white-space: nowrap;
  width: 15ch;
}

@media (min-width: 500px) {
}
