.plusMinusControls {
  display: flex;
  justify-content: center;
  align-items: center;
}
.increase,
.decrease,
.displayedValue {
  box-sizing: border-box;
  min-width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0.5rem;
}

.displayedValue {
  padding: 0.5rem 1rem;
}

.increase,
.decrease {
  border: 4px solid var(--darkPink);
  background-color: var(--lightPink);
  cursor: pointer;
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1);
}

.increase:hover,
.decrease:hover {
  background-color: var(--pink);
}

.calculator .plusMinusControls {
  margin-bottom: 1rem;
}

.calculator .increase,
.calculator .decrease {
  border: 4px solid var(--darkGreen);
  background-color: var(--white);
  cursor: pointer;
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.1);
}

.calculator .increase:hover,
.calculator .decrease:hover {
  background-color: var(--lightGreen);
}
